





























import { Component } from 'vue-property-decorator'
import { Location } from 'vue-router/types/router'
import { QueryParams } from '@movecloser/front-core'

import { PaginationProps } from '../../../dsl/molecules/Pagination'

import { ArticleCard, ArticleCardProps } from '../../../front/content/organisms/ArticleCard'
import { FiltersWrapper } from '../../../front/shared/organisms/FiltersWrapper'
import { FiltersWrapperProps } from '../../../front/shared/organisms/FiltersWrapper/FiltersWrapper.contracts'
import { translateContentToArticleCard } from '../../../front/content/organisms/ArticleCard/ArticleCard.helpers'

import { AbstractModuleUi } from '../../abstract/ui'
import { ArticlesListModule } from '../ArticlesList.contracts'
import { getPaginationConfig } from '../ArticlesList.helpers'
import { HeadingFormData } from '../../partials'

/**
 * Container component for the `ArticlesListModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArticlesListModuleUi>({
  name: 'ArticlesListModuleUi',
  components: { ArticleCard, FiltersWrapper }
})
export class ArticlesListModuleUi extends AbstractModuleUi<ArticlesListModule> {
  /**
   * Articles list amount of article per row.
   */
  public readonly AMOUNT_OF_ARTICLE_PER_ROW: number = 3

  /**
   * Articles list current page.
   */
  public currentPage: number = this.$route.query?.page
    ? parseInt(this.$route.query.page.toString(), 10) : 1

  /**
   * Articles list content.
   */
  public get articlesRows (): ArticleCardProps[][] | undefined {
    if (!this.content.articles?.length) {
      return
    }

    const translatedArticles = this.content.articles.map(translateContentToArticleCard)
    const articlesRows = []

    let leftRows: number = this.rowCount
    while (leftRows > 0) {
      const articles = translatedArticles.splice(0, this.AMOUNT_OF_ARTICLE_PER_ROW)
      if (articles.length > 0) {
        articlesRows.push(articles)
      }

      --leftRows
    }

    return articlesRows
  }

  /**
   * Articles list heading.
   */
  public get heading (): HeadingFormData | undefined {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Determines is list empty.
   */
  public get isEmptyList (): boolean {
    return this.totalArticles === 0
  }

  /**
   * Articles list heading.
   */
  public get filters (): FiltersWrapperProps {
    return {
      mobileTriggerTitle: this.$t('modules.ArticlesList.filters.label').toString(),
      params: this.content.filters ?? [],
      query: this.$route.query as QueryParams, // fixme
      override: { page: 1 }
    }
  }

  /**
   * Articles list pagination config.
   */
  public get paginationConfig (): Omit<PaginationProps, 'currentPage'> {
    return getPaginationConfig(this.$route.path, this.rowCount, this.totalArticles, undefined,
      this.$route.query)
  }

  /**
   * Articles row amount
   */
  public get rowCount () {
    return this.content.articlesPerPage / this.AMOUNT_OF_ARTICLE_PER_ROW
  }

  /**
   * Determines is pagination showed.
   */
  public get showPagination (): boolean {
    return this.content.showPagination && this.content.articlesPerPage < this.content.totalItems
  }

  /**
   * Articles amount per page
   */
  public get totalArticles (): number {
    return this.content.totalItems
  }

  /**
   * Handle query change.
   */
  public updateQuery (query: Location['query']): void {
    this.$router.push({
      path: this.$route.path,
      query
    })
  }
}

export default ArticlesListModuleUi
